import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./index.module.scss"
import Seo from "../components/seo"

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      enter: file(relativePath: { eq: "images/landingPage/enter.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <main className={styles.container}>
      <Seo
        title="Spécialiste du vélo électrique à Annemasse"
        description="Spécialiste du vélo électrique à Annemasse, Wistibike selectionne pour vous les meilleurs vélos électriques parmi les grandes marques"
      />
      <h1>Wistibike</h1>
      <section className={styles.frame}>
        <Img
          className={styles.image}
          fluid={data.enter.childImageSharp.fluid}
          alt="..."
        />
      </section>
      <Link className={styles.entrer} to="/ville">
        Entrer
      </Link>
    </main>
  )
}

export default Home
